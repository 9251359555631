import React from "react";

export default [
  {
    role: "Software Engineer II",
    company: "ecobee",
    logo: "/images/ecobee.png",
    location: "Toronto, ON",
    date: "2022 - Present",
    description:
      "Building commercial and residential load management solutions offering real savings and comfort for ecobee customers, while allowing our partner utility companies to ensure demand response and grid resiliency by automatically balancing supply and demand.",
    href: "https://www.scotiabank.com/gls/en/index.html#about",
  },

  {
    role: "Software Engineer I",
    company: "Amazon",
    logo: "/images/amazon.png",
    location: "Toronto, ON",
    date: "2020 - 2022",
    description:
      "Designed and developed microservices for Amazon's next generation of fulfillment operating system; enabling highly scalable, and fast deployable processes across Amazon's global fulfillment network, with the end goal of fully automated operations.",
    href: "https://www.scotiabank.com/gls/en/index.html#about",
  },

  {
    role: "Volunteer, Toronto Chapter",
    company: "Women of MENA in Tech",
    logo: "/images/mena.png",
    location: "Toronto, ON",
    date: "2022 - Present",
    description:
      "Part of WoMENAIT's Toronto team aiming to close the diversity and gender gap in STEM by connecting, mentoring, educating and elevating Middle Eastern and North African women in STEM globally.",
    href: "https://www.scotiabank.com/gls/en/index.html#about",
  },
  {
    role: "President, London Chapter ",
    company: "IAESTE",
    logo: "/images/iaeste.png",
    location: "London, ON",
    date: "2018 - 2019",
    description:
      "IAESTE's mission is to plan, promote, and facilitating international internships. Led a team of 14 executives by establishing common goals, supervising assigned projects, and creating a plan to meet the national goals of the organization",
    href: "https://iaeste.org",
  },
  {
    role: "Product Manager Intern",
    company: "Scotiabank",
    logo: "/images/scotia.png",
    location: "Waterloo, ON",
    date: "Summer 2019",
    description:
      "Delivered MVPs for two smart recommendation engines that aid Capital Market analysts in making relevant, timely decisions when pursuing clients, leading to $9-12M in revenues annually",
    href: "https://www.scotiabank.com/gls/en/index.html#about",
  },
  {
    role: "Software Engineer Intern",
    company: "Scotiabank",
    logo: "/images/scotia.png",
    location: "Toronto, ON & Waterloo, ON",
    date: "Summer 2018",
    description:
      "Delivered a co-branding project involving Scotiabank, Toronto Raptors, and Tangerine to create an innovative digital experience, engage users, and promote brand awareness",
    href: "https://www.scotiabank.com/gls/en/index.html#about",
  },

  {
    role: "Business Administration ",
    company: "Ivey Business School",
    logo: "/images/ivey.png",
    location: "London, ON",
    date: "2018 - 2020 ",
    description:
      "Bachelor of Arts, Honors Business Administration. Dual degree along with Software Engineering",
    href: "http://ivey.uwo.ca",
  },
  {
    role: "Software Engineering ",
    company: "Western Univrsity",
    logo: "/images/western.png",
    location: "London, ON",
    date: "2015 - 2020",
    description:
      "Bachelor of Engineering Science, Software Engineering. Received Western Excellence scholarship.",
    href: "http://worldofforex.com",
  },
];
